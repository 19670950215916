import React, { useEffect } from 'react'
import Navbar from '../component/Navbar'
import Catalogue from '../component/Catalogue';
import Testimonial from '../component/Testimonial';
import ContactUs from '../component/ContactUs';
import Footer from '../component/Footer';
import { useTranslation } from 'react-i18next';
import CarouselBanner from '../component/CarouselBanner';
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
import CookieConsentModal from '../component/CookieConsentModal';
import MyBusiness from '../component/MyBusiness';
import { Helmet } from 'react-helmet';
import { SpeedInsights } from "@vercel/speed-insights/react"

const Index = () => {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  // Access specific query parameters
  const paramValue = query.get('goto'); // Replace 'paramName' with the actual parameter name

  useEffect(() => {
    console.log(paramValue);
    if (paramValue) {
      scroller.scrollTo(paramValue, {
        duration: 500,
        smooth: true,
      });
    }
  }, [paramValue]);

  return (
    <div id='home'>
      <Helmet>
        <title>MANA Import Export</title>
        <meta name="name" content="MANA Import Export" />
        <meta name="description" content="MANA Import - Export" />
        <meta name="image" content="https://www.mana-ie.de/logo.png" />
        <meta name="theme-color" content="#000000" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css"
          integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA=="
          crossorigin="anonymous" referrerpolicy="no-referrer" />
      </Helmet>
      <SpeedInsights />
      <MyBusiness />
      <CookieConsentModal currentLanguage={i18n.language} />
      <Navbar />
      <div className="container-fluid position-relative p-0" style={{
        marginTop: '5em'
      }}>
        <div className='row'>
          <div className='col-md-12 col-lg-12 col-12'>
            <CarouselBanner language={i18n.language} />
          </div>
        </div>
      </div>
      <section id='about' style={{ background: 'white' }}>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-6 col-12">
              <img src="/images/owners/3.png" className='w-100' alt="" />
            </div>
            <div className="col-md-6 col-12 mt-lg-0 mt-md-3 mt-3">
              <div style={{ marginTop: '90px', marginRight: '35px' }}>
                <h3 className='font-inter text-colored text-bigliter'>{t("AboutUs")} <br />
                </h3>
                <p style={{ fontSize: '16px', textAlign: 'justify' }} className='mt-3 font-inter'>{t("AboutUsText")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Catalogue />
      <Testimonial />
      <ContactUs />
      <Footer />
    </div>
  )
}

export default Index;
