import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const messages = {
  en: {
    title: "Cookie Consent",
    message: "This website uses cookies to enhance your browsing experience. Do you accept the use of cookies?",
    accept: "Accept",
    deny: "Deny"
  },
  de: {
    title: "Cookie-Zustimmung",
    message: "Diese Website verwendet Cookies, um Ihr Surferlebnis zu verbessern. Akzeptieren Sie die Verwendung von Cookies?",
    accept: "Akzeptieren",
    deny: "Ablehnen"
  },
  hr: {
    title: "Pristanak na kolačiće",
    message: "Ova web stranica koristi kolačiće za poboljšanje vašeg iskustva pregledavanja. Prihvaćate li upotrebu kolačića?",
    accept: "Prihvati",
    deny: "Odbij"
  }
};

const CookieConsentModal = ({ currentLanguage }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const consent = Cookies.get('cookieConsent');
    if (!consent) {
      setIsOpen(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set('cookieConsent', 'accepted', { expires: 365 });
    setIsOpen(false);
  };

  const handleDeny = () => {
    Cookies.set('cookieConsent', 'denied', { expires: 365 });
    setIsOpen(false);
  };

  const { title, message, accept, deny } = messages[currentLanguage] || messages.en;

  if (!isOpen) return null;

  return (
    <div className="cookie-modal-wrapper">
      <div className="cookie-modal-content">
        <h2 className="cookie-modal-title">{title}</h2>
        <p className="cookie-modal-message">{message}</p>
        <div className="cookie-button-group">
          <button onClick={handleAccept} className="cookie-button cookie-button-primary">{accept}</button>
          <button onClick={handleDeny} className="cookie-button cookie-button-secondary">{deny}</button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsentModal;
