import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Flag from 'react-world-flags';

const languageOptions = [
  { value: 'de', label: 'German', flagCode: 'DE' },
  { value: 'en', label: 'English', flagCode: 'US' },
  { value: 'hr', label: 'Croatian', flagCode: 'HR' },
];

const customSingleValue = ({ data }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <Flag code={data.flagCode} height="16" style={{ marginRight: '8px' }} />
    {data.label}
  </div>
);

const customOption = (props) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div ref={innerRef} {...innerProps} style={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
      <Flag code={data.flagCode} height="16" style={{ marginRight: '8px' }} />
      {data.label}
    </div>
  );
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '30px',
    height: '30px',
    width: '100%',
    maxWidth: state.selectProps.menuIsOpen ? '200px' : '200px', // Wider when open
  }),
  menu: (provided) => ({
    ...provided,
    width: '100%',
    maxWidth: '200px', // Match the CSS max-width for mobile
  }),
  option: (provided) => ({
    ...provided,
    padding: '4px 8px', // Reduced padding
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '30px',
    padding: '0 6px',
    display: "flex",
    alignItems: 'center',
  }),
  singleValue: (provided) => ({
    ...provided,
    marginTop: '0',
    marginBottom: '0',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '4px',
    height: '30px',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '4px',
    height: '30px',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
  }),
};

const LanguageSelector = ({ handleChangeLanguage }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('i18nextLng') || 'de'
  );

  useEffect(() => {
    handleChangeLanguage({ value: selectedLanguage });
  }, [handleChangeLanguage, selectedLanguage]);

  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption.value);
    handleChangeLanguage(selectedOption);
  };

  return (
    <div>
      <Select
        value={languageOptions.find(option => option.value === selectedLanguage)}
        onChange={handleLanguageChange}
        options={languageOptions}
        components={{ SingleValue: customSingleValue, Option: customOption }}
        styles={customStyles}
        className="language-selector"
        isSearchable={false}
      />
    </div>
  );
};

export default LanguageSelector;
